
export default {
    name: "JobTraineeshipSection",
    components: {
        JobTraineeshipDatePicker: () =>
            import("@/components/job/jobpage/JobTraineeshipDatePicker"),
        JobSlideShowV3: () => import("@/components/job/jobpage/JobSlideShowV3"),
    },
    emits: ["interested-traineeship"],
    props: {
        traineeshipData: {
            type: Object,
            required: true,
        },
        hideDatePicker: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        contentBlock() {
            return this.traineeshipData?.content_blocks?.at(0)
        },
        headline() {
            return this.contentBlock?.headline
        },
        text() {
            return this.contentBlock?.text
        },
        mediaList() {
            return this.contentBlock?.media ?? []
        },
    },
    methods: {
        onShowMore(medium) {
            this.$fullscreenMedia.open(this.mediaList, { initialId: medium.id })
        },
    },
}
